<template>
  <v-container>
    <v-row>
      <v-col lg="6" sm="10">
        <v-text-field
          type="text"
          label="storyId"
          v-model="storyId"
        >
          <template slot:prepend>
            aa
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn @click="startStory()">
          {{ start ? '重新開始' :'開始' }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" style="height: 500px; overflow-y: scroll;" ref="actions">
        <template
          v-for="item in viewList"
        >
          <div :key="item.id" v-if="item.type === 'query'">
            query : {{item.query}}
          </div>
          <ActionView
            v-if="item.showAction"
            :action="item.action"
            :key="item.id"
            max-width="400px"
            :lock="true"
          />
          <v-divider class="ma-4" :key="item.id" v-if="item.type === 'divider'"></v-divider>
        </template>
      </v-col>
      <v-col cols="4">
        <v-text-field
          type="text"
          label="輸入文字訊息"
          @keyup.enter="sendQuery()"
          v-model="query"
        ></v-text-field>
        <div class="d-flex">
          <v-btn
            @click="sendQuery()"
            :disabled="end"
          >
            送出
          </v-btn>
          <v-btn
            @click="resendQuery()"
            :disabled="!start"
          >
            回答上一題
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-plusplus */
import ActionView from '../components/ActionView.vue';
import * as nseApi from '../helpers/nse-api';

export default {
  layout: 'normal',
  requireLogin: true,
  components: {
    ActionView,
  },
  data() {
    return {
      start: false,
      end: false,
      loading: false,
      storyId: '',
      query: '',
      visibleActionTypes: new Set([
        'play',
        'change-img',
      ]),
      viewList: [],
      lastItems: [],
      history: [],
      current: null,
      aidGen: 10000,
    };
  },
  created() {
    const { storyId } = this.$route.params;
    if (storyId) {
      this.storyId = storyId;
    }
  },
  methods: {
    parseAction(action) {
      return {
        id: this.aidGen++,
        group: 'pa-news',
        type: action.type,
        data: {
          ...action,
        },
      };
    },
    async startStory() {
      this.viewList = [];
      const { storyId } = this;
      const exist = await nseApi.checkStoryExist(storyId);
      if (exist) {
        this.viewList = [];
        this.query = '';
        this.history = [];
        this.current = null;
        this.start = true;
        this.end = false;
        await this.sendQuery(true);
      } else {
        // A
      }
    },
    async sendQuery(first = false) {
      this.lastItems = [];
      const {
        start,
        loading,
        storyId,
        query,
        current,
        lastItems,
      } = this;
      if (!start) return;
      if (loading) return;
      this.loading = true;
      this.history.push({
        query,
        current,
      });
      if (!first) {
        lastItems.push(this.aidGen);
        this.viewList.push({
          id: this.aidGen++,
          type: 'query',
          query,
        });
      }
      const results = await nseApi.callStoryTest(storyId, {
        text: query,
        current,
      });
      results.actions = results.actions.map((action) => this.parseAction(action));
      for (const action of results.actions) {
        lastItems.push(action.id);
      }
      await this.handleActions(results.actions);
      lastItems.push(this.aidGen);
      this.viewList.push({
        id: this.aidGen++,
        type: 'divider',
      });
      this.current = results.next;
      this.query = '';
      this.loading = false;
      this.$nextTick(() => {
        this.$refs.actions.scrollBy(0, 999);
      });
    },
    async resendQuery() {
      const {
        start,
        loading,
        storyId,
        query,
        history,
        lastItems,
      } = this;
      if (!start) return;
      if (loading) return;
      this.loading = true;
      const newLastItems = [];
      const ctx = history[history.length - 1] || {};
      newLastItems.push(this.aidGen);
      this.viewList.push({
        id: this.aidGen++,
        type: 'query',
        query,
      });
      const results = await nseApi.callStoryTest(storyId, {
        text: query,
        current: ctx.current,
      });
      results.actions = results.actions.map((action) => this.parseAction(action));
      for (const action of results.actions) {
        newLastItems.push(action.id);
      }
      const removeIds = new Set(lastItems);
      this.viewList = this.viewList.filter((action) => !removeIds.has(action.id));
      ctx.query = query;
      ctx.actions = results.actions;
      await this.handleActions(results.actions);
      newLastItems.push(this.aidGen);
      this.viewList.push({
        id: this.aidGen++,
        type: 'divider',
      });
      this.current = results.next;
      this.query = '';
      this.lastItems = newLastItems;
      this.loading = false;
      this.$nextTick(() => {
        this.$refs.actions.scrollBy(0, 999);
      });
    },
    async handleActions(actions) {
      const {
        visibleActionTypes,
      } = this;
      for (const action of actions) {
        const data = {
          id: action.id,
          type: 'action',
          showAction: visibleActionTypes.has(action.type),
          action,
        };
        if (action.type === 'end') {
          this.end = true;
        } else if (action.type === 'listen') {
          this.end = false;
        }
        this.viewList.push(data);
      }
    },
  },
};
</script>

<style>

</style>
